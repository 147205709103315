import React from 'react'
import { Box, Typography } from '@mui/material'
import { DownloadIcon } from '@flock/shared-ui'
import millify from 'millify'
import { PortfolioHomeDetailsPresentationalProps } from './portfolioHomeDetailsTypes'

const PortfolioHomeDetailsDesktop = (
  props: PortfolioHomeDetailsPresentationalProps
) => {
  const {
    leads,
    finalOfferPrice,
    finalRemodelPrice,
    itemsToShow,
    handleLoadMore,
    portfolioCsvUrl,
    isFinalValuation,
    hideRemodelCosts,
  } = props

  const showRangedOffer = !isFinalValuation

  // Get sums of offer range bounds, instead of rounded total +/- 4%
  const finalRangeLowerUnmillified = leads.reduce(
    (total: number, lead) =>
      total +
      Math.trunc(
        ((lead.valuationObject?.finalOfferPrice as number) * 0.96) / 1000
      ) *
        1000,
    0
  )

  const finalRangeUpperUnmillified = leads.reduce(
    (total: number, lead) =>
      total +
      Math.ceil(
        ((lead.valuationObject?.finalOfferPrice as number) * 1.04) / 1000
      ) *
        1000,
    0
  )

  const millifyOfferBound = (offerBound: number) =>
    millify(offerBound || 0, {
      precision: offerBound < 1000000 ? 0 : 2,
      lowercase: true,
      space: false,
      units: ['', 'k', 'm', 'b', 't'],
    })

  const getRangedOffer = (lead: any) => {
    let rangedOfferPercent = 0.04

    if (lead?.overrides) {
      const leadOverrides = JSON.parse(lead.overrides)
      if (leadOverrides?.rangedOfferPercent) {
        rangedOfferPercent = leadOverrides.rangedOfferPercent
      }
    }
    return `$${millifyOfferBound(
      Math.trunc(
        ((lead.valuationObject?.finalOfferPrice as number) *
          (1.0 - rangedOfferPercent)) /
          1000
      ) * 1000
    )}-$${millifyOfferBound(
      Math.ceil(
        ((lead.valuationObject?.finalOfferPrice as number) *
          (1.0 + rangedOfferPercent)) /
          1000
      ) * 1000
    )}`
  }
  const finalRangeLower = millify(finalRangeLowerUnmillified || 0, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })
  const finalRangeUpper = millify(finalRangeUpperUnmillified || 0, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })

  let remodelCostsTitle = 'Assumed Remodel Costs*'
  if (isFinalValuation) {
    remodelCostsTitle = 'Remodel Costs*'
  }
  if (hideRemodelCosts) {
    remodelCostsTitle = ''
  }

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="8px"
        sx={{
          borderRadius: '8px',
          backgroundColor: 'teal15.main',
          padding: '16px',
        }}
      >
        <Box display="flex" flexDirection="row" gap="8px">
          <Typography variant="c1" sx={{ width: '168px' }}>
            Address
          </Typography>
          <Typography
            variant="c1"
            color="gray5.main"
            sx={{ width: '160px', textAlign: 'right' }}
          >
            {remodelCostsTitle}
          </Typography>
          <Typography variant="c1" sx={{ width: '120px', textAlign: 'end' }}>
            Valuation {showRangedOffer ? 'Range' : ''}
          </Typography>
        </Box>
        {leads.slice(0, itemsToShow).map((item) => (
          <Box display="flex" flexDirection="row" gap="8px">
            <Typography variant="p2" sx={{ width: '220px' }} noWrap>
              {item.address?.streetNumber} {item.address?.formattedStreet}
            </Typography>

            <Typography
              variant="p2"
              color="gray5.main"
              sx={{ width: '108px', textAlign: 'end' }}
            >
              {!hideRemodelCosts
                ? `$${(
                    JSON.parse(item.overrides || '{}')?.remodelCosts ||
                    item.valuationObject?.remodelCosts
                  )?.toLocaleString()}`
                : ''}
            </Typography>

            <Typography variant="p2" sx={{ width: '120px', textAlign: 'end' }}>
              {showRangedOffer
                ? getRangedOffer(item)
                : `$${item.valuationObject?.finalOfferPrice?.toLocaleString()}`}
            </Typography>
          </Box>
        ))}
        {/* Show load more button if there are more items */}
        {itemsToShow < leads.length && (
          <Typography
            variant="p2"
            color="green4.main"
            onClick={handleLoadMore}
            sx={{ alignSelf: 'center', cursor: 'pointer', fontWeight: '500' }}
          >
            Load {Math.min(8, leads.length - itemsToShow)} more assets
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="8px"
        sx={{
          borderRadius: '8px',
          backgroundColor: 'teal15.main',
          padding: '16px',
          boxShadow: 8,
        }}
      >
        <Box display="flex" flexDirection="row" gap="8px">
          <Typography
            variant="p2"
            color="moneyGreen.main"
            sx={{ width: '220px', fontWeight: 500 }}
          >
            Total {isFinalValuation ? 'final' : 'initial'} valuation{' '}
            {showRangedOffer ? 'range' : ''}
          </Typography>
          <Typography
            variant="p2"
            color="green3.main"
            sx={{ width: '108px', textAlign: 'end' }}
          >
            {!hideRemodelCosts ? `$${finalRemodelPrice.toLocaleString()}` : ''}
          </Typography>
          <Typography
            variant="p2"
            color="moneyGreen.main"
            sx={{
              width: '120px',
              textAlign: 'end',
              fontWeight: 500,
              fontFamily: 'degular',
            }}
          >
            {showRangedOffer
              ? `$${finalRangeLower} - $${finalRangeUpper}`
              : `$${finalOfferPrice.toLocaleString()}`}
          </Typography>
        </Box>
      </Box>
      {portfolioCsvUrl && !isFinalValuation ? (
        <Box display="flex" flexDirection="column" gap="8px">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="p3" color="moneyGreen.main">
              Want to verify the assumptions?
            </Typography>
            <Box display="flex" gap="4px">
              <DownloadIcon width="20px" height="20px" />
              <Typography
                variant="c1"
                color="green4.main"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(portfolioCsvUrl, '_blank')
                }}
              >
                Download xlsx of per asset breakdown
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {!hideRemodelCosts ? (
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="p4" color="moneyGreen.main">
            {isFinalValuation
              ? '*Valuations are inclusive of the remodel costs.'
              : '*Valuations are inclusive of the assumed remodel costs.'}
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}

export default PortfolioHomeDetailsDesktop
