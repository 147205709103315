import { PageContainer, useFundConfig } from '@flock/shared-ui'
import React from 'react'

type OfferPageWrapperProps = {
  customerUuid: string
  children: React.ReactNode
}

const OfferPageWrapper = (props: OfferPageWrapperProps) => {
  const { children, customerUuid } = props
  const { companyName } = useFundConfig()

  return (
    <PageContainer
      title={`${companyName} | Offer Page`}
      trackingName="offer-page"
      trackingProperties={{
        customerUuid,
      }}
    >
      {children}
    </PageContainer>
  )
}

export default OfferPageWrapper
