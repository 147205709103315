import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import { useFundConfig } from '@flock/shared-ui'
import * as Sentry from '@sentry/gatsby'
// @ts-ignore
import TimeMe from 'timeme.js'
import {
  LandingGetCustomerDocument,
  LandingGetLeadDocument,
  LandingGetValuationDocument,
  LandingPostSlackMessageDocument,
  LandingUpdateLeadDocument,
  LandingGetMostRecentValuationRecordDocument,
  Core_Lead,
  Core_Valuation,
  Core_LeadDocument,
  GetPortfolioBreakdownCsvDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { localStore, UserEventType } from '@flock/utils'

import { useMutation, useQuery } from '@apollo/client'
import OfferPageWrapper from '../../components/CustomerOfferPageComponents/OfferPageWrapper'
import {
  decomposeSlackUrl,
  getSalesAssignee,
  isUuid,
  useRecordPageDuration,
} from '../../components/utils'
import { identify, shouldTrack, track } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'
import ErrorCard from '../../components/CustomerOfferPageComponents/ErrorCard'

import { OfferPageData } from '../../components/CustomerOfferPageComponents/offerPageTypes'
import OfferPage from '../../components/CustomerOfferPageComponents/OfferPage'
import {
  OfferPageContextProvider,
  OfferPageContextType,
} from '../../components/OfferPageComponents/OfferPageContext'
import Footer from '../../components/SharedComponents/Footer/Footer'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type PropertyEstimateProps = {
  params: {
    customer: string
  }
}

const TEST_PORTFOLIO_ULSTER_UUID = '672ed425-3fb1-4f55-8822-19b29d5a0e7d'

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  const { customer: customerUuid } = params
  useRecordPageDuration()

  const invalidUuid = typeof window !== 'undefined' && !isUuid(customerUuid)
  const [updateLead] = useMutation(LandingUpdateLeadDocument)
  const [postSlackMessage] = useMutation(LandingPostSlackMessageDocument)
  const [loading, setLoading] = useState<boolean>(true)

  const [pageData, setPageData] = useState<Partial<OfferPageData>>({})
  const [error, setError] = useState(false)
  const { refetch: refetchLead } = useQuery(LandingGetLeadDocument, {
    skip: true,
  })
  const { refetch: refetchGetPortfolioBreakdownCsvDocument } = useQuery(
    GetPortfolioBreakdownCsvDocument,
    {
      skip: true,
    }
  )
  const { refetch: refetchCustomer } = useQuery(LandingGetCustomerDocument, {
    skip: true,
  })
  const { refetch: refetchValuation } = useQuery(LandingGetValuationDocument, {
    skip: true,
  })
  const { refetch: refetchMostRecentValuationRecord } = useQuery(
    LandingGetMostRecentValuationRecordDocument,
    {
      skip: true,
    }
  )

  const {
    defaultOnboardingFee,
    defaultClosingAndLegalFee,
    defaultPricePerShare,
  } = useFundConfig()

  let allLeads = []
  let generatePortfolioCsv = true
  const initializePageData = useCallback(async () => {
    let customerDataResult = null
    try {
      const { data: refetchCustomerDataResult } = await refetchCustomer({
        input: {
          customerUuid,
        },
      })
      customerDataResult = refetchCustomerDataResult
    } catch (e) {
      setError(true)
      return
    }

    try {
      allLeads = customerDataResult?.customer?.customer?.leads as Core_Lead[]
    } catch (e) {
      setError(true)
      return
    }

    const selectedLeads = allLeads.filter(
      (lead: Core_Lead) =>
        lead?.isSelected &&
        lead?.address !== null &&
        lead?.address?.uuid !== TEST_PORTFOLIO_ULSTER_UUID &&
        lead?.valuationObject?.finalOfferPrice &&
        lead?.valuationObject?.finalOfferPrice > 0
    )

    // Check that each selectdo lead is either a single family or multi family computed valuation
    // (i.e. has valuation details and is not an overriden valuation)
    selectedLeads.forEach((lead) => {
      if (
        !(
          lead.valuationObject?.valuationCategory !==
          'VALUATION_CATEGORY_SINGLE_FAMILY'
        ) &&
        !(
          lead.valuationObject?.valuationCategory !==
          'VALUATION_CATEGORY_MULTI_FAMILY'
        )
      ) {
        generatePortfolioCsv = false
      }
    })

    if (
      !customerDataResult?.customer?.customer?.email &&
      !customerDataResult?.customer?.customer?.phoneNumber &&
      localStore?.getItem('disableTracking') !== 'true'
    ) {
      Sentry.captureException(
        new Error(`Offer page viewed for a customer without an email or phone`),
        {
          tags: {
            critical: true,
          },
          extra: {
            customerUuid,
          },
        }
      )
    }

    let portfolioBreakdownCsvResult = null
    if (generatePortfolioCsv) {
      const { data: getPortfolioBreakdownCsvResult } =
        await refetchGetPortfolioBreakdownCsvDocument({
          input: {
            customerUuid,
          },
        })
      portfolioBreakdownCsvResult = getPortfolioBreakdownCsvResult
    }

    if (
      shouldTrack() &&
      customerUuid &&
      customerUuid !== '00000000-0000-0000-0000-000000000000' &&
      !localStore?.getItem('customerUuid')
    ) {
      identify({
        userId: customerUuid as string,
      })

      localStore?.setItem('customerUuid', customerUuid)
    }

    const updatedPageData: Partial<OfferPageData> = {}

    const customerData = customerDataResult?.customer?.customer || {}
    let parsedOverrides: { [key: string]: any } = {}
    if (customerData?.overrides) {
      parsedOverrides = JSON.parse(customerData?.overrides)
    }

    const showCashFlowDeductions = false
    updatedPageData.showCashFlowDeductions = showCashFlowDeductions

    const {
      capexCosts,
      waiveOnboardingFee,
      cashTakeout,
      feeOverride,
      customerNameOverride,
      overrideCustomerName,
      brokerCommission,
      rentReduction,
      closingCosts,
      equityOverride,
      netYieldOverride,
      holdingPeriodOverride,
      hideRemodelCosts,
      overrideMortgage,
      mortgageOverride,
      rangedOfferPercent,
    } = parsedOverrides

    updatedPageData.hideRemodelCosts = hideRemodelCosts
    updatedPageData.holdingPeriodOverride = holdingPeriodOverride
    updatedPageData.closingCosts = closingCosts || defaultClosingAndLegalFee

    // Set onboarding fee, with overrides
    let onboardingFee = defaultOnboardingFee
    if (waiveOnboardingFee) {
      onboardingFee = 0
    } else if (feeOverride) {
      onboardingFee = feeOverride
    }

    // Sets customer name, with overrides
    updatedPageData.fullName = customerData.fullName || ''
    const displayFullName = updatedPageData.fullName?.replace(' Unknown', '')
    updatedPageData.fullName = displayFullName
    if (overrideCustomerName) {
      updatedPageData.fullName = customerNameOverride
    }

    updatedPageData.customerUuid = customerUuid as string
    updatedPageData.email = customerData.email as string
    updatedPageData.phoneNumber = customerData.phoneNumber as string

    updatedPageData.portfolioBreakdownCsv =
      portfolioBreakdownCsvResult?.getPortfolioBreakdownCsv?.s3Url || ''

    let isFinalValuation = false
    selectedLeads.forEach((lead) => {
      if (
        lead.valuationObject?.valuationType === 'VALUATION_TYPE_FINAL' ||
        !lead.valuationObject?.valuationType
      ) {
        isFinalValuation = true
      }
    })
    updatedPageData.isFinalValuation = isFinalValuation

    // set offer price as sum of all leads' final offer prices
    const finalOfferPrice = selectedLeads.reduce(
      (total: number, lead) =>
        total + (lead?.valuationObject?.finalOfferPrice || 0),
      0
    )
    // set mortgage as sum of all leads' mortgages
    let mortgage = selectedLeads.reduce(
      (total: number, lead) =>
        total +
        (lead?.valuationObject?.mortgageBalance ||
          JSON.parse(lead?.answers || '{}').mortgageAmount ||
          0),
      0
    )

    // So that we can override it to zero
    if (overrideMortgage) {
      mortgage = mortgageOverride
    }

    updatedPageData.mortgage = mortgage
    updatedPageData.finalOffer = finalOfferPrice

    const isPreliminary = finalOfferPrice === 0 || !finalOfferPrice
    updatedPageData.isPreliminary = isPreliminary

    const rangePercent = rangedOfferPercent || 0.04
    updatedPageData.offerLow = selectedLeads.reduce(
      (total: number, lead) =>
        total +
        Math.trunc(
          ((lead.valuationObject?.finalOfferPrice as number) *
            (1 - rangePercent)) /
            1000
        ) *
          1000,
      0
    )

    updatedPageData.offerHigh = selectedLeads.reduce(
      (total: number, lead) =>
        total +
        Math.ceil(
          ((lead.valuationObject?.finalOfferPrice as number) *
            (1 + rangePercent)) /
            1000
        ) *
          1000,
      0
    )

    // For all lead in leads, get the sum product of the netYield and finalOfferPrice
    const yieldSumProduct = selectedLeads.reduce((acc, lead) => {
      const netYield = lead.valuationObject?.netYield || 0
      const offerPrice = lead.valuationObject?.finalOfferPrice || 0
      return acc + netYield * offerPrice
    }, 0)

    const netYield = yieldSumProduct / finalOfferPrice
    updatedPageData.uwNetYield = netYield

    // Get the cost of debt from the most recent valuation record, regardless of selection
    let mostRecentValuationObject: Core_Valuation | undefined
    let mostRecentDate: Date | undefined

    allLeads.forEach((lead) => {
      if (lead.valuationObject && lead.valuationObject.finalOfferPrice) {
        const valuationDate = lead.valuationObject.createdAt
        if (
          (!mostRecentDate || valuationDate > mostRecentDate) &&
          lead.valuationObject.costOfDebt
        ) {
          mostRecentDate = valuationDate
          mostRecentValuationObject = lead.valuationObject
        }
      }
    })
    updatedPageData.costOfDebt = mostRecentValuationObject?.costOfDebt || 0

    // Get all expiry dates and set the earliest one
    const expiryDates = selectedLeads.map(
      (lead) => lead?.valuationObject?.expiresAt
    )
    const expiryDate = new Date(Math.min(...expiryDates))
    updatedPageData.expiryDate = expiryDate

    // for offer pages that are expired - we dont want to show cash flow anymore
    // they can change at initial underwrite again - and for final offers we dont want them to see
    // the bps of cash flow - they should reference their CA for what they get
    const isExpired = new Date().getTime() > expiryDate.getTime()

    updatedPageData.propertyValue = finalOfferPrice
    updatedPageData.capexCosts = capexCosts || 0
    updatedPageData.equityOverride = equityOverride || 0
    updatedPageData.cashTakeout = cashTakeout || 0
    updatedPageData.onboardingFee = onboardingFee
    updatedPageData.brokerCommission = brokerCommission || 0
    updatedPageData.rentReduction = rentReduction || 0

    // Set remodel costs as sum of all leads' remodel costs
    const remodelCosts = selectedLeads.reduce(
      (total: number, lead) =>
        total + (lead?.valuationObject?.remodelCosts || 0),
      0
    )
    updatedPageData.remodelCosts = remodelCosts || 0

    // Set days in remodel deduction as sum of all leads' days in remodel deduction
    const daysInRemodelDeduction = !updatedPageData.isFinalValuation
      ? 0
      : selectedLeads.reduce(
          (total: number, lead) =>
            total + (lead?.valuationObject?.daysInRemodel || 0),
          0
        ) || 0

    updatedPageData.daysInRemodelDeduction = daysInRemodelDeduction

    // So that we can override it to zero
    if (parsedOverrides.overrideDaysInRemodelDeduction) {
      updatedPageData.daysInRemodelDeduction =
        parsedOverrides.daysInRemodelDeduction
    }

    // new submarket rent deduction on outputs. Legacy valuations have it computed from the inputs.
    const submarketRentDeduction = selectedLeads.reduce(
      (total: number, lead) =>
        total + (lead?.valuationObject?.submarketRentDeduction || 0),
      0
    )

    // Sent rent reduction to 0 if it's preliminary
    updatedPageData.rentReduction = !updatedPageData.isFinalValuation
      ? 0
      : submarketRentDeduction || 0

    if (parsedOverrides.overrideRentReduction) {
      updatedPageData.rentReduction = parsedOverrides.rentReduction || 0
    }

    const feeModifier = 1 - onboardingFee
    const closingAndLegalTotal =
      defaultClosingAndLegalFee * updatedPageData.propertyValue

    // Calculate cash to close
    // Closing Costs, Broker Fees, Onboarding Fee, Mortgage Payoff, Contributor Cash Out Request, Estimated Capex (Immediate)
    // TODO: Add as customer override in the future
    const cashToClose =
      closingAndLegalTotal +
      updatedPageData.brokerCommission! +
      onboardingFee * finalOfferPrice +
      mortgage +
      updatedPageData.cashTakeout! +
      remodelCosts

    updatedPageData.cashToClose = cashToClose

    // Calculate portfolio uwCashOnCashYield
    // = (netYield - (cashToClose / (finalOffer + remodelCosts)) * costOfDebt) / (1 - (cashToClose / (finalOffer + remodelCosts)))
    // const uwCashOnCashYield =
    //   (netYield -
    //     (cashToClose / (finalOfferPrice + remodelCosts)) *
    //       updatedPageData.costOfDebt) /
    //   (1 - cashToClose / (finalOfferPrice + remodelCosts))

    // Until we align on buffer/re-calculation of this, net yield will always be manually overriden
    // updatedPageData.uwCashOnCashYield = netYieldOverride || uwCashOnCashYield
    updatedPageData.uwCashOnCashYield = netYieldOverride
    updatedPageData.hideNetYield =
      updatedPageData.uwCashOnCashYield === undefined ||
      updatedPageData.uwCashOnCashYield < 0.02 ||
      isExpired

    const equityAmount =
      Math.round(updatedPageData.propertyValue * feeModifier) -
      updatedPageData.mortgage -
      updatedPageData.capexCosts! -
      closingAndLegalTotal -
      updatedPageData.rentReduction! -
      updatedPageData.daysInRemodelDeduction!
    let shareCount = equityAmount / defaultPricePerShare
    updatedPageData.equityAmount = equityAmount

    // offer portfolio section checks if shareCount < 0
    if (Number.isNaN(shareCount)) {
      shareCount = -1
    }
    updatedPageData.shareCount = shareCount

    let isAgentSample = false
    if (typeof window !== 'undefined') {
      const { search } = window.location
      const searchParams = new URLSearchParams(search)
      isAgentSample = searchParams.get('agentSample') === 'true'
    }
    const salesAssigneeData = getSalesAssignee(
      customerData?.operator,
      isAgentSample
    )
    updatedPageData.offerPageContext = {
      ...salesAssigneeData,
      user: {
        fullName: customerData.fullName as string,
        email: customerData.email as string,
        phoneNumber: customerData.phoneNumber as string,
      },
    }

    if (customerData?.leads?.length && customerData?.leads[0]?.slackThreadUrl) {
      updatedPageData.slackThreadUrl = customerData?.leads[0]?.slackThreadUrl
    }

    // set tracking
    const notifySlack = async () => {
      if (shouldTrack()) {
        const hotjarUrl = `https://insights.hotjar.com/sites/2547595/playbacks?sort_by=created&filters=%7B%22AND%22:%5B%7B%22DAYS_AGO%22:%7B%22created%22:7%7D%7D%2C%7B%22CONTAINS%22:%7B%22all_page_paths%22:%22${customerUuid}%22%7D%7D%5D%7D`

        try {
          const { channel, threadTimestamp } = decomposeSlackUrl(
            updatedPageData.slackThreadUrl as string
          )
          await postSlackMessage({
            variables: {
              postSlackMessageInput: {
                channel,
                threadTimestamp,
                text: `Customer viewed portfolio offer page. (<${hotjarUrl}|Hotjar>) cc: <@${salesAssigneeData.slackId}>`,
              },
            },
          })
        } catch (e) {
          console.error(e)
        }
      }
    }

    track('portfolio-estimate-viewed', {
      offerPageStatus: 'final',
      pageViewed: TimeMe.currentPageName,
      isFinalValuation,
      actionType: UserEventType.PAGE_DURATION,
    })

    notifySlack()

    const firstLead = selectedLeads[0]
    let staticDocumentOverrides = {}
    if (firstLead?.overrides) {
      const overridesJson = JSON.parse(firstLead?.overrides)
      if (overridesJson?.documents) {
        staticDocumentOverrides = overridesJson.documents
      }
    }
    updatedPageData.staticDocumentOverrides = staticDocumentOverrides

    if (firstLead?.documents?.length) {
      updatedPageData.customerDocuments =
        firstLead?.documents as Core_LeadDocument[]
    }

    updatedPageData.leads = selectedLeads || []

    setPageData(updatedPageData)
    setLoading(false)
  }, [
    postSlackMessage,
    refetchLead,
    refetchValuation,
    updateLead,
    refetchMostRecentValuationRecord,
  ])

  const { offerPageContext } = pageData

  useEffect(() => {
    if (!invalidUuid) {
      initializePageData()
    }
  }, [initializePageData, invalidUuid])

  if (invalidUuid || error) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        id="calendlyPopupRoot"
        sx={{ backgroundColor: 'gray1.main', overflow: 'hidden' }}
      >
        <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
      </Box>
    )
  }

  return (
    <>
      <OfferPageWrapper customerUuid={customerUuid}>
        {loading ? (
          <Box height="auto" pt="50px" sx={{ backgroundColor: 'gray1.main' }}>
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <OfferPageContextProvider
            value={offerPageContext as OfferPageContextType}
          >
            <Box
              height="auto"
              id="calendlyPopupRoot"
              sx={{ backgroundColor: 'trustBlue.main' }}
            >
              <OfferPage pageData={pageData as OfferPageData} />
            </Box>
          </OfferPageContextProvider>
        )}

        <SectionLayout name="footer" backgroundColor="darkBackground.main">
          <Grid item xs={12}>
            <Box id="calendlyPopupRoot">
              <Footer />
            </Box>
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
