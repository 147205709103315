import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  StarOutlinedIcon,
  StarFilledIcon,
  HouseOutlinedIcon,
  HouseFilledIcon,
  DocumentsIcon,
  DocumentsFilledIcon,
  MapIcon,
  MapFilledIcon,
} from '@flock/shared-ui'
import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import OfferDetails from './OfferDetails'
import OfferDocuments from './OfferDocuments/OfferDocuments'
import OfferPagePaper from '../OfferPageComponents/OfferPagePaper'
import OfferPageSection from '../OfferPageComponents/OfferPageSection'

import { OfferPageData } from './offerPageTypes'

import { scrollIntoView, useInView } from '../utils'

import OfferPageSidebar from '../OfferPageComponents/OfferPageSidebar/OfferPageSidebar'
import OfferExchange from './OfferExchange/OfferExchange'
import PortfolioHomeDetails from './PortfolioHomeDetails/PortfolioHomeDetails'
import FloatingMessage from '../OfferPageComponents/FloatingMessage'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type OfferPageProps = {
  pageData: OfferPageData
}

const OfferPage = (props: OfferPageProps) => {
  const { pageData } = props

  const {
    zipcode,
    cityStateZipcode,
    latitude,
    longitude,
    portfolioBreakdownCsv,
    propertyCondition,
    scheduleInspectionRequested,
    contributionAgreementRequested,
    mortgage,
    hideValuation,
    finalOffer,
    uwCashOnCashYield,
    prelimOffer,
    offerLow,
    offerHigh,
    expiryDate,
    updateScheduleInspectionRequested,
    updateContributionAgreementRequested,
    updateRefreshOfferRequested,
    refreshOfferRequested,
    saamValuationType,
    street,
    slackThreadUrl,
    inspectionDate,
    staticDocumentOverrides,
    leads,

    preinspectionSurveyCompleted,
    transactionType,
    isOverriddenAddress,
    fullName,
    equityOverride,
    cashTakeout,
    shareCount,
    equityAmount,
    onboardingFee,
    brokerCommission,
    daysInRemodelDeduction,
    closingCosts,
    rentReduction,
    customerDocuments,
    customerStage,
    customerStatus,
    isFinalValuation,
    hideRemodelCosts,
  } = pageData

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const offerPortfolioText = isMobile
    ? 'Valuations are based on sale comps, condition, and market data.'
    : `Valuations are based on sale comps, property condition, and market rent data.`

  const [selectedSection, setSelectedSection] = useState(0)
  const [sidebarHeight, setSidebarHeight] = useState(0)
  const sidebarRef = useCallback((domElement: HTMLDivElement) => {
    if (domElement !== null) {
      setSidebarHeight(domElement.clientHeight)
    }
  }, [])

  const sectionIds = useMemo(
    () => ['offer', 'details', 'exchange', 'documents'],
    []
  )

  const currentSection = useInView(sectionIds)

  const sidebarItems = [
    {
      title: 'Valuation',
      unselectedIcon: StarOutlinedIcon,
      selectedIcon: StarFilledIcon,
      id: 'offer',
    },
    {
      title: 'Details',
      unselectedIcon: HouseOutlinedIcon,
      selectedIcon: HouseFilledIcon,
      id: 'details',
    },
    {
      title: 'Exchange',
      unselectedIcon: MapIcon,
      selectedIcon: MapFilledIcon,
      id: 'exchange',
      hide: !shareCount || shareCount < 0,
    },
    {
      title: 'Documents',
      unselectedIcon: DocumentsIcon,
      selectedIcon: DocumentsFilledIcon,
      id: 'documents',
    },
  ]

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setSelectedSection(sectionIds.indexOf(currentSection))
  }, [currentSection, sectionIds])

  // if the lead only provided a full name, remove the "Unknown" part
  const displayFullName = fullName?.replace(' Unknown', '')

  return (
    <>
      <OfferPageSidebar
        sidebarRef={sidebarRef}
        sidebarHeight={sidebarHeight}
        leadStatus={customerStatus}
        sidebarItems={sidebarItems}
        onChangeSidebar={onChangeSidebar}
        selectedSection={selectedSection}
      />
      <OfferPageSection
        name="offer-details"
        backgroundColor="gray1.main"
        id="offer"
      >
        {!isMobile && <Box pt="38px" />}
        <OfferDetails
          name={displayFullName}
          uwCashOnCashYield={uwCashOnCashYield}
          street={street}
          equityAmount={equityAmount}
          zipcode={zipcode}
          cityStateZipcode={cityStateZipcode}
          expiryDate={expiryDate}
          latitude={latitude}
          longitude={longitude}
          hideValuation={hideValuation}
          finalOffer={finalOffer}
          prelimOffer={prelimOffer}
          showRangedValuation={!isFinalValuation}
          offerLow={offerLow}
          offerHigh={offerHigh}
          preinspectionSurveyCompleted={preinspectionSurveyCompleted}
          propertyCondition={propertyCondition}
          leadStage={customerStage}
          isOverriddenAddress={isOverriddenAddress}
          saamValuationType={saamValuationType}
          leadStatus={customerStatus}
          inspectionDate={inspectionDate}
          transactionType={transactionType}
          scheduleInspectionRequested={scheduleInspectionRequested}
          updateScheduleInspectionRequested={updateScheduleInspectionRequested}
          contributionAgreementRequested={contributionAgreementRequested}
          updateContributionAgreementRequested={
            updateContributionAgreementRequested
          }
          updateRefreshOfferRequested={updateRefreshOfferRequested}
          refreshOfferRequested={refreshOfferRequested}
        />
      </OfferPageSection>
      <OfferPageSection
        name="home-details"
        backgroundColor="gray1.main"
        id="details"
        shouldHide={
          isOverriddenAddress &&
          transactionType !== Core_TransactionType.TransactionTypePortfolio_721
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={isMobile ? '16px' : '24px'}
        >
          <Typography color="moneyGreen.main" variant="cta">
            {offerPortfolioText}
          </Typography>
          <OfferPagePaper>
            <PortfolioHomeDetails
              leads={leads}
              portfolioCsvUrl={portfolioBreakdownCsv}
              isFinalValuation={isFinalValuation}
              hideRemodelCosts={hideRemodelCosts}
            />
          </OfferPagePaper>
        </Box>
      </OfferPageSection>
      <OfferPageSection
        name="appreciation-potential"
        backgroundColor="gray1.main"
        id="exchange"
      >
        <Box
          pb={isMobile ? '16px' : '24px'}
          display="flex"
          gap="32px"
          flexDirection="column"
        >
          <Typography variant="cta" color="moneyGreen.main">
            You receive ownership in the Fund based on the value of your homes.
          </Typography>
        </Box>
        <OfferPagePaper>
          <OfferExchange
            finalOffer={finalOffer || prelimOffer}
            street={street}
            showRangedValuation={!isFinalValuation}
            onboardingFee={onboardingFee}
            closingCosts={closingCosts}
            mortgage={mortgage || 0}
            transactionType={transactionType}
            rentReduction={rentReduction || 0}
            cashTakeout={cashTakeout || 0}
            daysInRemodelDeduction={daysInRemodelDeduction || 0}
            brokerCommission={brokerCommission || 0}
            equityOverride={equityOverride || 0}
          />
        </OfferPagePaper>
      </OfferPageSection>
      <OfferPageSection
        name="offer-documents"
        backgroundColor="gray1.main"
        id="documents"
      >
        <OfferDocuments
          leadDocuments={customerDocuments || []}
          staticDocumentOverrides={staticDocumentOverrides || {}}
          slackThreadUrl={slackThreadUrl}
        />
      </OfferPageSection>
      {isMobile && (
        <OfferPageSection
          name="floating-cta-mobile"
          backgroundColor="gray1.main"
          id="floating-cta-mobile"
        >
          <FloatingMessage />
        </OfferPageSection>
      )}
    </>
  )
}

export default OfferPage
