import { useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import {
  PortfolioHomeDetailsProps,
  PortfolioHomeDetailsPresentationalProps,
} from './portfolioHomeDetailsTypes'

const usePortfolioHomeDetails: (
  props: PortfolioHomeDetailsProps
) => PortfolioHomeDetailsPresentationalProps = (
  props: PortfolioHomeDetailsProps
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { leads } = props

  const [itemsToShow, setItemsToShow] = useState<number>(8)
  const handleLoadMore = () => {
    // Show additional 8 items or remaining items if less than 8
    setItemsToShow((prevCount) => Math.min(prevCount + 8, leads.length))
  }

  const finalOfferPrice = leads.reduce(
    (total, lead) => total + (lead?.valuationObject?.finalOfferPrice || 0),
    0
  )
  const finalRemodelPrice = leads.reduce(
    (total, lead) =>
      total +
      (JSON.parse(lead.overrides || '{}')?.remodelCosts ||
        lead?.valuationObject?.remodelCosts ||
        0),
    0
  )

  return {
    ...props,
    isMobile,

    finalOfferPrice,
    finalRemodelPrice,

    handleLoadMore,
    itemsToShow,

    hideRemodelCosts: true,
  }
}

export default usePortfolioHomeDetails
